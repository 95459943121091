// Import libraries
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Img from 'gatsby-image';


// Import navigation context
import NavigationContext from '../context/navigation';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    position: relative;
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh - 15rem);
    overflow: hidden;

    & .content {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-rows: 1fr 6rem;
        align-items: center;
        justify-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);

        & .container {
            display: block;
            padding: 0 var(--padding-right) 0 var(--padding-left);
            width: 100%;
            max-width: var(--max-width);
        }

        & .inner h1 {
            font-size: calc(40px + (80 - 40) * ((100vw - 320px) / (2200 - 320)));
            line-height: 1.2;
            color: #fff;
            
            & .primary {
                color: var(--color-primary);
            }

            @media only screen and (min-width: 1600px) {
                font-size: 55px;
            }

            @media only screen and (min-width: 1900px) {
                font-size: 70px;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            outline: 0;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledVideo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    
    & .video {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;

        &[poster] {
            object-fit: cover;
        }
    }
`;

const StyledOurExperience = styled.div`
    background-color: var(--color-background-2);

    & .content {
        display: grid;
        grid-template-rows: repeat(3, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: 10rem var(--padding-right) 10rem var(--padding-left);
        text-align: center;
        max-width: var(--max-width);
        margin: 0 auto;
        
        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .feature__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }
    }
`;

const StyledWhoAreWe = styled.div`
    display: grid;
    grid-template-columns: calc(40% - 2.5rem) calc(60% - 2.5rem);
    grid-column-gap: 5rem;

    @media only screen and (max-width: 1400px) {
        grid-template-columns: 50% 50%;
    }

    @media only screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
    }

    & .content {
        display: grid;
        grid-template-rows: repeat(3, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: 10rem var(--padding-right) 10rem var(--padding-left);
        text-align: center;
        
        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .feature__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }
    }

    & .video {
        display: grid;
        align-items: center;
        justify-items: center;

        @media only screen and (max-width: 1000px) {
            padding-bottom: 10rem;
        }

        &__inner {
            position: relative;
            
            @media only screen and (max-width: 1000px) {
                text-align: center;
            }
        }

        &__video {
            width: 80%;
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 80%;
            height: 100%;
            background-color: rgba(0, 0, 0, .6);
            z-index: 1;
            display: grid;
            align-items: center;
            justify-items: center;
            outline: 0;
            transition: background-color .25s ease,
                        opacity .25s ease,
                        visibility .01s .25 ease;
            cursor: pointer;

            @media only screen and (max-width: 1000px) {
                left: 50%;
                transform: translateX(-50%);
            }

            & .video-play-btn {
                display: grid;
                align-items: center;
                justify-items: center;
                color: #fff;
                width: 10rem;
                height: 10rem;
                font-size: 4rem;
                border: 3px solid #fff;
                border-radius: 50%;
                opacity: 0.5;
                transition: opacity .25s ease;

            }

            &.playing {
                opacity: 0;
                visibility: hidden;
            }

            &.playing .play-btn {
                opacity: 0;
            }
        }

        &__overlay:hover {
            background-color: rgba(0, 0, 0, .4);

            .vide-play-btn {
                opacity: 1;
            }
        }

        &__overlay.playing:hover {
            background-color: rgba(0, 0, 0, .6);
            opacity: 1;

            .video-play-btn {
                opacity: 0;
                display: none;
            }
        }
    }
`;

const StyledTeamMembers = styled.div`
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 5rem;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);
    background-color: var(--color-background-2);

    @media only screen and (max-width: 900px) {
        grid-template-columns: 1fr;
    }

    &:nth-of-type(2n + 1) {
        grid-template-columns: 1fr 40%;
        background-color: #fff;

        @media only screen and (max-width: 900px) {
            grid-template-columns: 1fr;
        }
    }

    & .team-member__image {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 900px) {
            grid-template-columns: 50%;
        }
        
        & .image {
            display: block;
            width: 100%;
            outline-width: 5px;
            outline-color: var(--color-primary);
            outline-style: solid;
            outline-offset: 5px;
        }
    }

    &:nth-of-type(2n + 1) .team-member__image {
        grid-column: 2 / 3;
        grid-row: 1 / 2;

        @media only screen and (max-width: 900px) {
            grid-column: auto;
            grid-row: auto;
        }
    }

    & .team-member__content {
        display: grid;
        grid-template-rows: repeat(3, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: 0 var(--padding-right) 0 var(--padding-left);
    }

    &:nth-of-type(2n + 1) .team-member__content {
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        @media only screen and (max-width: 900px) {
            grid-column: auto;
            grid-row: auto;
        }
    }

    & .team-member__name {
        font-size: var(--font-size-body-h2);
        font-weight: 600;
        line-height: var(--line-height-body-h2);
    }

    & .team-member__title {
        font-size: 1.8rem;
        font-weight: 100;
        margin-top: -1.5rem;
        font-style: italic;
    }

    & .team-member__summary {
        font-size: var(--font-size-body-p);
        font-weight: var(--font-weight-body-p);
        line-height: var(--line-height-body-p);
        text-align: justify;
    }
`;


// Create component
const OverOnsPagina = () => {
    const [videoPlaying, setVideoPlaying] = useState(false);


    const data = useStaticQuery(graphql`
        query {
            bannerVideo: allFile(filter: {relativePath: {eq: "banner/over-ons/banner.mp4"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }

            bannerVideoThumbnail: file(relativePath: { eq: "banner/over-ons/thumbnail.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            companyVideo: allFile(filter: {relativePath: {eq: "banner/index/banner.mp4"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }

            companyVideoThumbnail: file(relativePath: { eq: "banner/index/thumbnail.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            teamMemberMarcelPijpstra: file(relativePath: { eq: "team-members/marcel-pijpstra.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            teamMemberCarolienPijpstraTebrugge: file(relativePath: { eq: "team-members/carolien-pijpstra-tebrugge.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);


    // Team members
    const teamMembers = [
        {
            id: 1,
            image: data.teamMemberMarcelPijpstra.childImageSharp.fluid,
            name: 'Marcel Pijpstra',
            title: 'Eigenaar',
            summary: 'Mijn naam is Marcel Pijpstra. Ik ben altijd goed geweest met cijfers. De stap naar de HBO deeltijd opleiding Accountancy op de Avans Hogeschool Breda was dan ook een logische keuze. Na ruime tijd werkzaam te zijn geweest bij verschillende accountantskantoren, was het tijd om hier, in Zierikzee, zelf een administratiekantoor te openen in 2016.<br><br>Persoonlijk contact met de klant staat bij mij op nummer &eacute;&eacute;n. Ik vind het belangrijk om altijd mezelf te blijven! Dit heeft me ook gebracht waar ik nu sta. Effici&euml;ntie staat ook erg hoog in het vaandel bij mij. Door middel van automatisering en innovaties kan ik mezelf richten op het adviseren en inzicht geven bij de klant. Het beste halen uit deze innovaties, maakt dit vak voor mij het leukst.<br><br>In mei 2019 hebben Carolien en ik besloten om onze kennis te bundelen en hebben we de 2 van ons bedrijfsnaam "Up2Date" verzilverd door middel van een samenwerking! Ik, de sociale en commerci&euml;le man die vaak buiten de deur is en Carolien het brein op kantoor!<br><br>Naast het zweten op kantoor, heb ik natuurlijk ook andere hobby\'s. Ik vind het leuk om met computers te werken en kan hier echt mijn ei in kwijt. Ook ga ik graag lekker uit eten en met vrienden op stap.'
        },
        {
            id: 2,
            image: data.teamMemberCarolienPijpstraTebrugge.childImageSharp.fluid,
            name: 'Carolien Pijpstra Tebrugge',
            title: 'Mede-eigenaar',
            summary: 'Zoals jullie wellicht weten ben ik de vrouw van Marcel. We zijn vanaf 2013 samen en zijn op 1 september 2017 in het huwelijksbootje gestapt.<br><br>Na en tijdens, mijn opleiding Accountancy Duaal aan Hogeschool Rotterdam ben ik werkzaam geweest bij diverse accountantskantoren. Ik heb bewust een duale studie gekozen zodat ik de lesstof direct in de praktijk kon uitoefenen. Nadat mijn man Marcel in 2016 voor zichzelf begon, heb ik per 1 mei 2019 de keuze gemaakt om medevennoot te worden.<br><br>Het mooie van onze samenwerking is dat wij beide erg divers zijn. Marcel is commercieel, kan goed met mensen om gaan en is vaak buiten kantoor te vinden. Daarnaast is hij erg goed in het opstellen van begrotingen en prognoses en helpt hij klanten met bedrijfsfinancieringen. Daarentegen ben ik iemand die graag met cijfers in de weer is, ik ben erg punctueel en zorgvuldig. In de meeste gevallen ben ik ook degene die creatief te werk gaat als het gaat om nieuwsbrieven, de website etc. In de praktijk komt het vaak voor dat ik de jaarcijfers samenstel en dat Marcel deze gaat bespreken op locatie. Een goede match als zeg ik het zelf!<br><br>In mijn priv&eacute;leven vind ik familie erg belangrijk. Uit eten, borrelen of een terrasje pakken met familie of vrienden doen we vrij regelmatig. Daarnaast maken we graag (verre) reizen en genieten we van het leven!'
        }
    ];


    // Handle scrolling down to next section on page
    const handleScrollDown = () => {
        // Get header height difference between static header height and scollling header height and get banner height
        const headerHeightDifference = (parseFloat(window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size')) * 15) - (parseFloat(window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size')) * 10);
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        

        // Scroll to place underneath banner
        window.scrollTo({
            top: (bannerHeight + headerHeightDifference),
            left: 0,
            behavior: 'smooth'
        });
    };


    const { viewportSize } = useContext(NavigationContext);
    const positionBannerVideo = useCallback(() => {
        // Reset styles to default
        document.querySelector('.banner .video').style.width = '100%';
        document.querySelector('.banner .video').style.height = 'auto';
        document.querySelector('.banner .video').parentNode.style.left = '0px'

        // Get banner video height and banner height
        const bannerVideoHeight = document.querySelector('.banner .video').clientHeight;
        const bannerHeight = document.querySelector('.banner').clientHeight;

        // Check if banner video height is smaller than banner height
        if (bannerVideoHeight < bannerHeight) {
            // Stretch video's to fill banner height
            document.querySelector('.banner .video').style.width = 'auto';
            document.querySelector('.banner .video').style.height = bannerHeight + 'px';
            
            // Reposition video in banner in middle of screen based on viewport width and video width
            const bannerVideoWidth = document.querySelector('.banner .video').clientWidth;
            document.querySelector('.banner .video').parentNode.style.left = -((bannerVideoWidth - viewportSize.width) / 2) + 'px';
        } else {
            // Set video width to 100%
            document.querySelector('.banner .video').style.width = '100%';
            document.querySelector('.banner .video').style.height = 'auto';
            document.querySelector('.banner .video').parentNode.style.left = '0px'
        }
    }, [viewportSize.width]);


    useEffect(() => {
        setTimeout(() => {
            positionBannerVideo();
        }, 250);
    }, [positionBannerVideo]);


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO
                title="Over Ons"
                description="Met ons kantoor neemt u een partner in handen die als een verlengde van uw bedrijf werkt. Persoonlijk contact en transparantie heeft een hoog vaandel bij ons."
            />
            <Layout>
                <StyledBanner image={data.bannerVideoThumbnail} className="banner">
                    <StyledVideo>
                        <video
                            className="video"
                            src={data.bannerVideo.edges[0].node.publicURL}
                            autoPlay
                            loop
                            muted
                            playsInline
                            poster={data.bannerVideoThumbnail.childImageSharp.fluid.src}>
                            <track kind="captions" srcLang="en" label="english_captions"></track>
                        </video>
                    </StyledVideo>

                    <div className="content">
                        <div className="inner container">
                            <h1>Wilt u graag weten waar het allemaal begon? Dit is <span className="primary">ons verhaal</span>.</h1>
                        </div>

                        <div className="container">
                            <div className="scroll-down-button" role="button" tabIndex={0} onClick={handleScrollDown} onKeyDown={handleScrollDown}>
                                <FontAwesomeIcon icon={faArrowDown} className="icon" />
                            </div>
                        </div>
                    </div>
                </StyledBanner>

                <StyledOurExperience>
                    <div className="content">
                        <h1>Wat kunt u van ons verwachten?</h1>

                        <p className="feature__summary">Ons administratiekantoor heeft geen uurtje- factuurtje mentaliteit maar werkt met een vast prijs principe zodat u nooit voor verassingen komt te staan. Heeft u een vraag over uw administratie? Dan kunt u altijd contact met ons opnemen. Met Up2Date Administratie neemt u een partner in handen die als een verlengde van uw bedrijf werkt. Transparantie en persoonlijk contact staan bij ons hoog in het vaandel.</p>
                        
                        <p className="feature__summary">Wij zijn een vooruitstrevend administratiekantoor op het gebied van automatisering. Weg met al het papierwerk, wij werken met gebruiksvriendelijke en effici&euml;nte software. Hierdoor is uw administratie 24/7 bereikbaar en kunt u uw boekhouding ook op uw smartphone of tablet bekijken.</p>
                        
                        <p className="feature__summary">Wilt u weten of wij de juiste partner voor u en uw onderneming zijn? Neem dan gerust vrijblijvend contact op voor een afspraak zodat u ons beter kunt leren kennen.</p>
                    </div>
                </StyledOurExperience>

                <StyledWhoAreWe>
                    <div className="content">
                        <h1>Achter de schermen</h1>

                        <p className="feature__summary">In 2016 kwam Up2Date Administratie tot leven in Zierikzee. In mei 2019 is mijn vrouw, Carolien, ook toegetreden tot ons kantoor en groeien we sneller dan ooit tevoren. Samen kunnen we ons nog meer richten op het persoonlijk contact met u, de klant.</p>

                        <p className="feature__summary">Wij hebben een breed netwerk van fiscalisten, accountants en verzekering- en hypotheekadviseurs zodat we u overal mee van dienst kunnen zijn.</p>

                        <p className="feature__summary">Doormiddel van het filmpje kunt u een kijkje nemen achter de schermen van Up2Date Administratie. Wij nodigen u ook graag uit bij ons op kantoor voor een vrijblijvend gesprek zodat u kunt ori&euml;nteren of wij de juiste partner voor u zijn. Uiteraard komen wij ook graag bij u langs.</p>
                    </div>

                    <div className="video">
                        <div className="video__inner">
                            <video
                                className="video__video"
                                src={data.companyVideo.edges[0].node.publicURL}
                                controls
                                poster={data.companyVideoThumbnail.childImageSharp.fluid.src}
                                id="video"
                            >
                                <track kind="captions" srcLang="en" label="english_captions"></track>
                            </video>
                            <div
                                className={"video__overlay" + (videoPlaying ? " playing" : "")}
                                role="button"
                                tabIndex={0} 
                                onClick={() => {
                                    if (videoPlaying) {
                                        document.querySelector('#video').pause();
                                        setVideoPlaying(false);
                                    } else {
                                        document.querySelector('#video').play();
                                        setVideoPlaying(true);
                                    }
                                }}
                                onKeyDown={() => {
                                    if (videoPlaying) {
                                        document.querySelector('#video').pause();
                                        setVideoPlaying(false);
                                    } else {
                                        document.querySelector('#video').play();
                                        setVideoPlaying(true);
                                    }
                                }}
                            >
                                <div className="video-play-btn">
                                    <FontAwesomeIcon icon={faPlay} className="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </StyledWhoAreWe>

                
                {teamMembers.map(teamMember => (
                    <StyledTeamMembers className="team-member" key={teamMember.id}>
                        <div className="team-member__image">
                            <Img
                                fluid={teamMember.image}
                                alt={teamMember.name + " profile picture"}
                                className="image"
                            />
                        </div>

                        <div className="team-member__content">
                            <h3 className="team-member__name">{teamMember.name}</h3>
                            <span className="team-member__title">{teamMember.title}</span>
                            <p className="team-member__summary" dangerouslySetInnerHTML={{ __html: teamMember.summary }}></p>
                        </div>
                    </StyledTeamMembers>
                ))}
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default OverOnsPagina;
